import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/Home.jpg";

export default function Header() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="header">
      <SharedHeader
        image={header}
        classNameImage="header-image"
        imageAltText=""
        align="left"
        titlesize="display-1"
        titlecolor="blue"
        title="Wir"
        text="machen Unternehmensnachfolgende fit für die digitale Zukunft!"
        textsize="headline-2"
        buttontext="Jetzt Kontakt aufnehmen"
        buttonTextBackgroundColor="blue"
        buttonTextColor="white"
        buttonIconColor="yellow"
        buttonGridTemplateAreas={`'. grid2 .' '. . text'`}
        link="https://utum.typeform.com/to/Hn4KEeuM"
        target="_blank"
      />
    </section>
  );
}
