import React from "react";
import Button from "../../shared/button";
import teaser from "../../../assets/img/components/teaser/teaser.png";

export default function Teaser() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="teaser">
    <header
      className="header teaser grid py-0"
    >
      <div className="col-12 lg:col-6 header-left">
        <img
          className="wallpaper-image img-h-500"
          src={teaser}
          alt=""
        />
      </div>
      <div
        className={`col-12 lg:col-6 header-left xs:p-grid-standard`}
      >
        <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 justify-end height-100 align-center pxt-grid-standard content ic-40 xl:ic-80">
          <div className="col-12">
            <p className={`tc-black headline-1`}>Werde treibende Kraft in Deinem Unternehmen!</p>
            <p className="pt-20">Wenn Du an NextGen4Bavaria teilnehmen möchtest, bleibe auf dem Laufenden!</p>
          </div>
          <div className="local-header--button col-12 pt-20 grid">
            <div className="local-header--button---info">
              <p className="tc-black fs-button m-a">Nächster Jahrgang: Q1|25 - Q1|26</p>
            </div>
          <Button
                  text="Jetzt Kontakt aufnehmen"
                  gridTemplateAreas={`'. . text' '. grid2 .'`}
                  textBackgroundColor="black"
                  textColor="white"
                  iconColor="yellow"
                  link="https://utum.typeform.com/to/Hn4KEeuM"
                  target="_blank"
                />
      </div>
      </div>
      </div>
      </header>
    </section>
  );
}